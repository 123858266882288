/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.smartsecret-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #F2F7FF;
}

/* Large Screen Styles */
@media (min-width: 787px) {
  .smartsecret-container {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  .smartsecret-left {
    flex: 0 0 50%;
    padding-right: 40px;
  }

  .smartsecret-right {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
  }

  .smartsecret-right img {
    max-width: 100%;
    height: auto;
  }

  .smartsecret-header h1 {
    font-size: 32px;
    margin-bottom: 24px;
  }

  .smartsecret-subheader p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 32px;
  }

  .feature-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 40px;
  }

  .feature-item {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .feature-icon {
    width: 20px;
    height: 20px;
  }

  .feature-text {
    font-size: 14px;
    line-height: 22px;
  }

  .smartsecret-buttons {
    display: flex;
    gap: 16px;
  }

  .schedule-call,
  .learn-more {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  .schedule-call {
    background: linear-gradient(276.37deg, #1772FF 5%, #1359C5 109.47%);
    color: #ffffff;
    border: none;
  }

  .learn-more {
    background-color: transparent;
    color: #4C82EF;
    border: 1px solid #4C82EF;
  }
}

@media (max-width: 786px) {
  .smartsecret-container {
    flex-direction: column;
    padding: 20px 16px;
  }

  .smartsecret-right {
    margin-bottom: 20px;
  }

  .smartsecret-right img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  .smartsecret-left {
    order: 2;
  }

  .smartsecret-header h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .smartsecret-subheader p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .feature-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 32px;
  }

  .feature-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .feature-icon {
    width: 15px;
    height: 20px;
  }

  .feature-text {
    font-size: 12px;
    line-height: 20px;
  }

  .smartsecret-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .schedule-call,
  .learn-more {
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  .schedule-call {
    background: linear-gradient(276.37deg, #1772FF 5%, #1359C5 109.47%);
    color: #ffffff;
    border: none;
  }

  .learn-more {
    background-color: transparent;
    color: #4C82EF;
    border: 1px solid #4C82EF;
  }
}