/* Footer.css */
.footer-container {
    background-color: #f0f4fc;
    padding: 5rem 2rem;
    color: #333;
  }
  
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  @media (min-width: 1280px) {
    .footer-content {
      grid-template-columns: 1fr 1fr;
      gap: 5rem;
    }
  }
  
  .footer-main-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
  }
  
  .footer-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #2F54EB;
  }
  
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .footer-link {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #2F54EB;
  }
  
  .footer-address {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .footer-newsletter {
    display: flex;
    flex-direction: column;
  }
  
  .newsletter-subtitle {
    margin: 1rem 0;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .newsletter-input-container {
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .newsletter-input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 1rem;
  }
  
  .newsletter-subscribe-btn {
    width: 100%;
    background-color: #2F54EB;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .newsletter-subscribe-btn:hover {
    background-color: #1d3ab8;
  }
  
  @media (max-width: 640px) {
    .footer-main-section {
      grid-template-columns: 1fr;
    }
  }