
.offerings {
  text-align: center;
  font-family: 'Poppins';
  color: #333333;
  padding-top: 8px;
  
  padding-bottom: 8px;
}

.offerings-title {
  font-size: 48pt;
  font-weight: 800;
  letter-spacing: 0.24px;
  line-height: 120%;
  margin: 0;
  text-transform: uppercase;
}

.offerings-subtitle {
  font-family: 'Poppins';
  font-size: 16pt;
  font-weight: 500;
  /* letter-spacing: -0.32px; */
  line-height: 32px;
  margin-top: 16px;
}


  
.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 74pt;
  background-color: #f9f9f9; 
}

.tab-navigation {
  display: flex;
  justify-content: space-evenly; /* Equally space tabs horizontally */
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #dddee0;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 954pt; /* Optional: constrain the max width if desired */
  height: 72px;
  padding-left: 12pt;
}

.tab-item {
  padding: 4px 16px;
  height: 100%;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #7c808a;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tab-item.active {
  color: #333333;
  border-top: 3px solid #4c82ef;
}



  
.chat-preview {
  background: radial-gradient(
    circle at top right, 
    #F2f7ff 0%, /* Arc color */
    #F2f7ff calc(752px), /* Adjust arc radius */
    #E7EEFE  calc(752px), /* Background starts after the arc */
    #E7EEFE 100%
  );
  /* background-image: url('../assets/background.svg'); */
  border-radius: 8px;
  background-size: cover; /* Makes the image cover the entire section */
  background-repeat: no-repeat; 
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: 954pt;
  margin: 20px auto;
  padding-top: 36px;
  text-align: center; /* Centers inline elements like images */
   height: 443px;
}


.chat-preview img {
 
  height: 100%;  
   
  margin: 0;  
}
  

  
  
  
  
  .description-section {
    width: 584pt;
    padding-top: 16px;
    text-align: center;
 
    /* position: sticky; */
    top: 0;
    z-index: 1000;
    margin: 20px auto;
  }
  
  .description-section h1 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.408px;
    color: #333333;
    margin-bottom: 8px;
  }
  
  .description-section p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: -0.408px;
    color: #7d8088;
  }
  

  /* Hide TabNavigation and apply vertical layout for mobile */
  @media (max-width: 768px) {
    .offerings {
        text-align: center;
        font-family: 'Poppins';
        color: #333333;
        padding: 15px; /* Reduced padding */
        width: 100%; /* Use full width */
        box-sizing: border-box; /* Include padding in width calculation */
    }

    .fullcontainer {
        width: 100%; /* Use full width instead of max-width */
        padding: 0 15px; /* Add side padding */
        box-sizing: border-box;
    }

    .offerings-title {
        font-size: 20px; /* Convert from pt to px, slightly reduced */
        font-weight: 700;
        letter-spacing: 0.24px;
        line-height: 120%;
        margin: 0 0 10px 0; /* Added bottom margin */
        text-transform: uppercase;
    }

    .offerings-subtitle {
        font-family: 'Poppins';
        font-size: 14px; /* Convert from pt to px */
        font-weight: 300;
        line-height: 1.5; /* More flexible line height */
        margin-top: 10px;
        padding: 0 15px; /* Add side padding */
    }

    .chat-preview {
      width: 100%;
      max-width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      overflow: hidden;
  }
  
  .chat-preview img {
      max-width: 100%; /* Ensure image doesn't exceed container width */
      height: auto; /* Maintain aspect ratio */
      display: block; /* Remove any potential inline spacing */
      margin: 0 auto; /* Center the image */
      object-fit: contain; /* Ensure the entire image is visible */
      object-position: center; /* Center the image within its container */
  }
    .subheading {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        text-align: center;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .description-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px; /* Reduced padding */
        text-align: center;
        width: 100%; /* Full width */
        box-sizing: border-box;
    }

    .description-section h1 {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-bottom: 8px;
        padding: 0 15px; /* Add side padding */
    }

    .description-section p {
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 14px;
        color: #7d8088;
        padding: 0 15px; /* Add side padding */
        max-width: 100%;
        box-sizing: border-box;
    }
}