body {
    margin: 0;
    font-family: 'Poppins';
  }
  
  .spin {
    text-align: center;
    background: #4c82ef;
    padding: 70px 376px;
  }
  
  .title {
    font-size: 48px;
    color: white;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
  }
  
  .subtitle {
    font-size: 16px;
    color: white;
    margin-bottom: 38px;
    line-height: 24px;
    text-align: center;
  }
  
  .spin-container {
   
    display: flex;
    justify-content: center;
    gap: 48px;
  }
  
  .spin-card {
    width: 256px;
    height: 190px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #eaeaea;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Evenly space image and title vertically */
    align-items: center;
    text-align: center;
    /* Remove extra padding so that space-evenly works as intended */
    padding: 0; 
  }
  
  .card-title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin: 0; /* Ensure no extra margin affects spacing */
  }
  @media (max-width: 1200px) {
    .spin {
      padding: 40px 50px; /* Adjust padding for medium screens */
    }
  
    .title {
      font-size: 40px; /* Slightly smaller title font size */
      line-height: 46px;
    }
  
    .subtitle {
      font-size: 14px; /* Adjust subtitle font size */
      margin-bottom: 28px;
      line-height: 22px;
    }
  
    .spin-container {
      gap: 24px; /* Reduce gap between cards for smaller screens */
      flex-wrap: wrap; /* Allow wrapping for cards on smaller screens */
    }
  
    .spin-card {
      width: 220px; /* Slightly smaller cards */
      height: 180px;
      padding: 10px;
    }
  
    .card-title {
      font-size: 16px; /* Adjust font size */
      line-height: 24px;
    }
  }
  @media (max-width: 768px) {
    .spin {
      padding: 40px 16px; /* Reduce padding for mobile */
    }
  
    .title {
      font-size: 32px; /* Adjust title font size */
      line-height: 38px;
    }
  
    .subtitle {
      font-size: 14px; /* Adjust subtitle font size */
      margin-bottom: 16px;
      line-height: 20px;
    }
  
    .spin-container {
      gap: 16px; /* Reduce gap between cards */
    }
  
    .spin-card {
      width: 100%; /* Make cards take full width */
      max-width: 280px; /* Constrain maximum width */
      padding: 12px; /* Add padding for better spacing */
    }
  
    .card-title {
      font-size: 14px; /* Smaller title font for mobile */
      line-height: 20px;
    }
  }