.logoff {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 184px 120px;
    background-color: #ffffff;
  }
  
  .text-section {
    max-width: 50%;
  }
  
  .text-section h1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 48px;
    letter-spacing: 0.24px;
    line-height: 120%;
    color: #333333;
  }
  
  .text-section p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #333333;
    margin-top: 24px;
  }
  
  .buttons {
    margin-top: 48px;
    display: flex;
    gap: 16px;
  }
  
  .schedule-button {
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background: linear-gradient(276.37deg, rgba(23,114,255,1) 5%, rgba(19,89,197,1) 109.47%);
    color: #ffffff;
    border: 2px solid #4c82efcc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .demo-button {
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background-color: #ffffff;
    color: #4c82ef;
    border: 1px solid #4c82ef;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .image-section {
    max-width: 50%;
  }
  
  .image-section img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 786px) {
    .logoff {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px; /* Reduced padding */
      background-color: #ffffff;
      width: 100%; /* Ensure full width */
      box-sizing: border-box; /* Include padding in width calculation */
      overflow-x: hidden; /* Prevent horizontal scrolling */
    }
  
    .text-section {
      max-width: 100%;
      width: 100%;
      text-align: center; /* Center align text */
      padding: 0 15px; /* Add some side padding */
      box-sizing: border-box;
    }
    
    .text-section h1 {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 28px; /* Slightly smaller for better fit */
      letter-spacing: 0.24px;
      color: #333333;
      width: 100%; /* Ensure full width */
      word-wrap: break-word; /* Wrap long words */
    }
    
    .text-section p {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5; /* More compact line height */
      color: #333333;
      margin-top: 24px;
      width: 100%; /* Ensure full width */
      word-wrap: break-word; /* Wrap long words */
    }
    
    .buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center; /* Center buttons */
      gap: 12px;
      width: 100%; /* Full width */
      flex-wrap: wrap; /* Allow wrapping if needed */
    }
    
    .schedule-button,
    .demo-button {
      padding: 10px 15px; /* Slightly less padding */
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 14px; /* Slightly smaller font */
      line-height: 1.4;
      border-radius: 5px;
      cursor: pointer;
      max-width: 100%; /* Ensure buttons don't overflow */
    }
    
    .image-section {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 15px; /* Add side padding */
      box-sizing: border-box;
    }
    
    .image-section img {
      width: 100%;
      height: auto;
      max-width: 400px; /* Limit image width */
      object-fit: contain; /* Ensure image scales properly */
    }
  }