.herosection {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f9f9f9;
  padding: 40px 20px;
  gap: 32px;
  /* height: 800px; */
}

.text-container {
  text-align: center;
  padding: 0 16px;
}

.text-container h1 {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 48px;
  color: #333333;
  line-height: 120%;
  margin: 0 0 16px;
  width: 787px;
}

.text-container p {
  font-family: 'Poppins',;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 32px;
  margin: 0;
  width: 579pt;
}

.illustration-container {
  position: relative;
  top: -200px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
 /* Adjust as needed (e.g., set to 1 if you want it on top) */
  pointer-events: none; 
}



@media screen and (max-width: 768px) {
  .bot-graphics {
    display: block;
    width: 100%;
    height: auto;
  }
}


.cta-container {
  display: flex;
  gap: 16px;
}

.schedule {
  text-decoration: none;      /* Removes the underline */
  display: inline-block;      /* Allows setting width, height, and vertical centering */
  text-align: center;         /* Centers the text horizontally */
  vertical-align: middle;  
  background: linear-gradient(276.37deg, rgba(23,114,255,1) 5%, rgba(19,89,197,1) 109.47%);
  color: #ffffff;
  padding: 12px 24px;
  border: 2px solid rgba(76, 130, 239, 0.8);
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  width: 164pt;
}

.demo {
  text-decoration: none;      /* Removes the underline */
  display: inline-block;      /* Allows setting width, height, and vertical centering */
  text-align: center;         /* Centers the text horizontally */
  vertical-align: middle;  

  background-color: #ffffff;
  color: rgba(76, 130, 239, 1);
  padding: 12px 24px;
  border: 1px solid rgba(76, 130, 239, 1);
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Poppins',;
  font-weight: 400;
  font-size: 16px;
  width: 164pt;
}

.schedule:hover, .demo:hover {
  opacity: 0.8;
}



@media screen and (max-width: 768px) {
  .herosection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    padding-bottom: 0;
    overflow: hidden;
    margin-bottom: 0;
    /* height: 650px; */
  }

  .text-container {
    text-align: center;
    padding: 0 15px;
    width:100%;
  }
  
  .text-container h1 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 32px;
    color: #333333;
    line-height: 120%;
    margin: 0 0 16px;
    width: 100%;
  }
  
  .text-container p {
    font-family: 'Poppins',;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 32px;
    margin: 0;
    width: 100%;
  }


  .cta-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .schedule {
    text-decoration: none;     
    display: inline-block;      
    text-align: center;         
    vertical-align: middle;  
    background: linear-gradient(276.37deg, rgba(23,114,255,1) 5%, rgba(19,89,197,1) 109.47%);
    color: #ffffff;
    padding: 8px 18px;
    border: 2px solid rgba(76, 130, 239, 0.8);
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;  /* Ensure a proper font-family value */
    font-weight: 300;
    font-size: 14px;
  }
  
  .demo {
    text-decoration: none;     
    display: inline-block;      
    text-align: center;        
    vertical-align: middle;  
    background-color: #ffffff;
    color: rgba(76, 130, 239, 1);
    padding: 8px 18px;
    border: 1px solid rgba(76, 130, 239, 1);
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
  
  .schedule:hover, .demo:hover {
    opacity: 0.8;
  }
  .illustration-container {
    position: relative;
    top: -100px;
    /* height: 400x; */
    width: 400px;
    margin-bottom: 0;
    pointer-events: none; 
  }




}


@media (max-width: 400px) {
  .schedule, .demo {
    padding: 6px 12px;
    font-size: 12px;
  }
}