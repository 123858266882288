.customerqueries {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
}

.query_header {
  text-align: center;
  margin-bottom: 40px;
}

.query_header .title {
  font-weight: 800;
  font-size: 48px;
  letter-spacing: 0.24px;
  line-height: 120%;
  margin: 0;
  color: #333333;
}

.query_header .subtitle {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.32px;
  line-height: 32px;
  margin-top: 16px;
  color: #333333;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.card-container {
  display: flex;
  gap: 120px; /* spacing between image and info card */
  justify-content: center;
  align-items: center; /* vertically center both cards */
  width: 1200px;
  margin: 0 auto;
  background-color: #f0f4ff;
  padding: 20px 20px 20px 10px; /* top, right, bottom, left */
  border-radius: 8px;
}


.info-card {
  display: flex;
  flex-direction: column;
  gap: 11px;
  max-width: 536px;
  padding: 20px;
}

.info-card__title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.408px;
  line-height: 30px;
  color: #333333;
  margin: 0;
}

.info-card__description {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.408px;
  line-height: 170%;
  color: #333333;
  margin: 0;
}

.info-card__features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 11px;
}

.info-card__feature {
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
}

.info-card__feature-icon {
  flex-shrink: 0;
}

.info-card__feature-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.32px;
  line-height: 32px;
  color: #333333;
  margin: 0;
}

.info-card__button {
  width: 164px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #4C82EF;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-card__button-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4C82EF;
  padding: 10px;
}

@media (max-width: 1024px) {
  .query_header {
    padding-top: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .query_header .title {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0.24px;
   
    margin: 0;
    color: #333333;
  }
  
  .query_header .subtitle {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 32px;
    margin-top: 16px;
    color: #333333;
  }
  .card-container {
    flex-direction: row; /* Stack items vertically */
    gap: 40px; /* Reduce spacing between image and info card */
    width: 90%; /* Adjust width to fit smaller screens */
    padding: 16px; /* Reduce padding */
  }

  .info-card {
    max-width: 100%; /* Allow cards to take full width */
    padding: 16px; /* Reduce padding */
  }

  .info-card__title {
    font-size: 16px; /* Reduce font size */
    line-height: 24px; /* Adjust line height */
  }

  .info-card__description {
    font-size: 14px; /* Reduce font size */
    line-height: 150%; /* Adjust line height */
  }

  .info-card__features {
    gap: 6px; /* Reduce spacing between features */
  }

  .info-card__feature-text {
    font-size: 14px; /* Reduce font size */
  }

  .info-card__button {
    width: 140px; /* Reduce button width */
    height: 40px; /* Reduce button height */
    margin-top: 16px; /* Adjust margin */
  }

  .info-card__button-text {
    font-size: 14px; /* Reduce font size */
    padding: 8px; /* Adjust padding */
  }
}

@media (max-width: 786px) {
  .card-container {
    flex-direction: column; /* Stack items vertically */
    gap: 40px; /* Reduce spacing between image and info card */
    width: 90%; /* Adjust width to fit smaller screens */
    padding: 16px; /* Reduce padding */
  }

  .info-card {
    display: flex;
    flex-direction: column;
    gap: 11px;
    max-width: 536px;
    padding: 40px;
    padding-left: 60px;
  }
  
  .info-card__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.408px;
    line-height: 30px;
    color: #333333;
    margin: 0;
    padding-top: 20px;
  }
  
  .info-card__description {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.408px;
    line-height: 170%;
    color: #333333;
    margin: 0;
  }
  .info-card__image{
    height: 70%;
    width: 70%;
    padding-top: 20px; /* Adds top padding */
    display: block; /* Ensures block-level behavior */
 
    object-fit: contain; 

  }
  .info-card__features {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 11px;
  }
  
  .info-card__feature {
    display: flex;
    flex-direction: row;
    gap: 11px;
    align-items: center;
  }
  
  .info-card__feature-icon {
    flex-shrink: 0;
  }
  
  .info-card__feature-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 32px;
    color: #333333;
    margin: 0;
  }
  
  .info-card__button {
    width: 164px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #4C82EF;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .info-card__button-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4C82EF;
    padding: 10px;
  }
}