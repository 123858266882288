/* Base styles */
body {
    margin: 0;
    font-family: 'Poppins';
}

.header {
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 128px;
    background-color: #f9f9f9;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08); */
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    margin-right: 8px;
    height: 30px;
}

.logo span {
    font-size: 20px;
    font-weight: 600;
    color: #1a73e8;
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    margin: 0 32px; /* Increased horizontal margin for spacing */
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: color 0.2s ease;
}

.schedule-button {
    margin: 0 32px; /* Same margin as nav items for consistent spacing */
    background: linear-gradient(276.37deg, #1772ff 5%, #1359c5 109.47%);
    color: #ffffff;
    border: 2px solid #4c82efcc;
    padding: 10px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    transition: background 0.3s ease, transform 0.2s ease;
}


.nav a:hover {
    color: #1a73e8;
}


.schedule-button:hover {
    background: linear-gradient(276.37deg, #1359c5 5%, #1772ff 109.47%);
    transform: scale(1.05);
}

.schedule-button:active {
    transform: scale(0.98);
}

/* Menu icon for mobile */
.menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

/* Mobile navigation sidebar */
.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    background-color: #f0f4ff;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    flex-direction: column;
}

.mobile-nav a {
    margin: 15px 0;
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: color 0.2s ease;
}

.mobile-nav a:hover {
    color: #1a73e8;
}

.mobile-nav .schedule-button {
    margin-top: 20px;
}

/* Active mobile navigation */
.mobile-nav.active {
    display: flex;
}
@media (max-width: 1024px) {
    .header {
        padding: 16px 64px;
    }

    .nav a {
        font-size: 14px;
        margin: 0 12px;
    }

    .schedule-button {
        padding: 8px 12px;
        font-size: 14px;
    }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .header {
        padding: 16px;
        justify-content: space-between;
    }

    .nav {
        display: none;
    }

    .menu-icon {
        display: block;
    }
}
