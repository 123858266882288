.howitworks {
    text-align: center;
    padding: 20px;
    padding-top: 184px;
    padding-left: 120px; /* Add padding on the left */
    padding-right: 120px;
    background-color: #fff;
}

.howitworks img {
    margin-bottom: 16px;
}

.howitworks h2 {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 48px;
    letter-spacing: 0.24px;
    color: #333333;
    line-height: 120%;
    margin-bottom: 16px;
}

.howitworks p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.32px;
    color: #333333;
    line-height: 32px;
    margin-bottom: 32px;
}

.steps {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.step {
    width: 364px;
    padding: 36px;
    text-align: center;
}

.step img {
    margin-bottom: 24px;
}

.step h3 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.41px;
    color: #333333;
    line-height: 30px;
    margin-bottom: 11px;
}

.step p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.41px;
    color: #7d8088;
    line-height: 170%;
}


@media screen and (min-width: 690px) and (max-width: 890px) {
    .howitworks {
        padding-left: 20px;
        padding-right: 20px;
    }

    .steps {
        flex-direction: row;
        align-items: center;
    }

    .step {
        width: 100%;
        max-width: 500px;
        margin-bottom: 20px;
    }

    .howitworks h2 {
        font-size: 36px;
    }
}
@media (max-width: 768px) {
    .howitworks {
        text-align: center;
        padding: 20px 15px; /* Reduced horizontal padding */
        padding-top: 40px; /* Reduced top padding */
        background-color: #fff;
    }
    
    .howitworks img {
        margin-bottom: 16px;
        max-width: 100%; /* Ensure images don't overflow */
    }
    
    .howitworks h2 {
        font-family: 'Poppins';
        font-weight: 800;
        font-size: 32px; /* Reduced font size for mobile */
        letter-spacing: 0.24px;
        color: #333333;
        line-height: 120%;
        margin-bottom: 16px;
    }
    
    .howitworks p {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 14px; /* Reduced font size */
        letter-spacing: -0.32px;
        color: #333333;
        line-height: 1.5; /* More compact line height */
        margin-bottom: 24px;
    }
    
    .steps {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center steps horizontally */
        width: 100%; /* Full width */
    }
    
    .step {
        width: 100%; /* Full width */
        max-width: 364px; /* Maximum width */
        padding: 24px; /* Reduced padding */
        text-align: center;
        box-sizing: border-box; /* Include padding in width calculation */
    }
    
    .step img {
        margin-bottom: 16px;
        max-width: 100%; /* Ensure images don't overflow */
        height: auto;
    }
    
    .step h3 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 16px; /* Slightly reduced font size */
        letter-spacing: -0.41px;
        color: #333333;
        line-height: 1.5;
        margin-bottom: 8px;
    }
    
    .step p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px; /* Reduced font size */
        letter-spacing: -0.41px;
        color: #7d8088;
        line-height: 1.5;
    }
}